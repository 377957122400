import { useEffect, useState } from "react";
// TODO: 絶対パスに対応
// import UserApi from "@/api/v2/UserApi";
import UserApi from "../../api/v2/UserApi";

export const useUserRanking = (univId, type) => {
  const [ranking, setRanking] = useState({ month: [], semester: [], year: [] });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (univId) {
      const fetchRanking = async () => {
        const userApi = new UserApi();
        const monthRanking = userApi.getRanking(univId, type, "month");
        const semesterRanking = userApi.getRanking(univId, type, "semester");
        const yearRanking = userApi.getRanking(univId, type, "year");

        try {
          const rankings = await Promise.all([
            monthRanking,
            semesterRanking,
            yearRanking,
          ]);
          const rankings_ = rankings.map((ranking) => {
            const ranking_ = ranking.map(({ user, count }) => {
              return {
                user: {
                  userId: user.user_id,
                  iconType: user.icon_type,
                  username: user.user_name,
                  screenName: user.screen_name,
                },
                count: count,
              };
            });
            return ranking_;
          });
          setRanking({
            month: rankings_[0],
            semester: rankings_[1],
            year: rankings_[2],
          });
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };
      fetchRanking();
    }
  }, [univId]);
  return { ranking, error, isLoading };
};
