import React, { useCallback } from "react";
import { Button, Header, Icon, Label } from "semantic-ui-react";

import Modal from "../Modal";
import SegmentArea from "../../SegmentArea";
import styles from "./styles.module.scss";
import { WEEK } from "../../../../constants/timetable";
// TODO: 絶対パスに対応
// import { openReviewPageOf } from "@/utils/openReviewPage";
// import * as CRITERION from "@/constants/criterion";
import { openReviewPageOf } from "../../../../../../utils/openReviewPage";
import * as CRITERION from "../../../../../../constants/criterion";

const CellDetailModal = (props) => {
  const { cell, open, setOpen, onDelete } = props;
  const {
    lecture: {
      course: { name: course_name, course_group, semester },
      teacher_group: { teachers },
    },
    student_count,
    attendance_freq,
    scoring_method,
    credit_easiness,
    content_quality,
    credit,
    row,
    column,
  } = cell;
  const day = WEEK[column];
  const period = row;
  const criterion = {
    attendance_freq,
    scoring_method,
    credit_easiness,
    content_quality,
  };

  const handleDeleteButtonClick = () => {
    onDelete(cell);
    setOpen(false);
  };

  const handleOpenReview = useCallback(() => {
    const {
      lecture: {
        course: {
          univ: { univ_id },
          name,
          course_group,
          semester,
        },
        teacher_group: { teachers: teachers_ },
      },
    } = cell;
    openReviewPageOf(
      univ_id,
      name,
      course_group,
      semester,
      teachers_.map((teacher) => teacher.name)
    );
  }, [cell]);

  return (
    <Modal open={open} setOpen={setOpen} className={styles.header}>
      <div
        style={{
          maxWidth: 400,
          width: "100%",
          margin: "4px auto 0",
        }}
      >
        <h3>{`${day}曜${period}限`}</h3>
        <SegmentArea style={{ margin: "8px 0" }}>
          <Header
            as="h2"
            className={styles.sans_serif}
            style={{ margin: 0, color: "#333" }}
          >
            {course_name}
          </Header>
          <div style={{ color: "#666" }}>
            <p style={{ margin: 0, lineHeight: "1.2em", fontSize: "16px" }}>
              {course_group}／{semester}
            </p>
            <p style={{ margin: "2px 0 0", lineHeight: "1.2em" }}>
              <Icon name="graduation cap" />
              {teachers.map((teacher) => teacher.name).join("／")}
            </p>
            <p style={{ margin: 0, lineHeight: "1.2em" }}>
              <Icon name="check" />
              {credit}単位
            </p>
            <p style={{ margin: 0, lineHeight: "1.2em" }}>
              <Icon name="users" />
              {student_count || "-"}人<br />
            </p>

            {criterion.attendance_freq && ( // 評価が存在する場合のみ表示
              <div style={{ marginTop: 8 }}>
                <Label size="mini" image>
                  {CRITERION.ATTENDANCE_FREQ.label}
                  <Label.Detail>
                    {CRITERION.getLabel(
                      CRITERION.ATTENDANCE_FREQ,
                      criterion.attendance_freq,
                      "label"
                    )}
                  </Label.Detail>
                </Label>
                <Label size="mini" image>
                  {CRITERION.SCORING_METHOD.label}
                  <Label.Detail>
                    {CRITERION.getLabel(
                      CRITERION.SCORING_METHOD,
                      criterion.scoring_method,
                      "label"
                    )}
                  </Label.Detail>
                </Label>
                <Label size="mini" image>
                  {CRITERION.CREDIT_EASINESS.label}
                  <Label.Detail>
                    {criterion.credit_easiness?.toFixed(1)}
                  </Label.Detail>
                </Label>
                <Label size="mini" image>
                  {CRITERION.CONTENT_QUALITY.label}
                  <Label.Detail>
                    {criterion.content_quality?.toFixed(1)}
                  </Label.Detail>
                </Label>
              </div>
            )}
          </div>
        </SegmentArea>
        <div style={{ textAlign: "right", margin: "0 0 8px" }}>
          <Button basic disabled>
            <Icon name="pencil alternate" />
            投稿
          </Button>
          <Button basic onClick={handleDeleteButtonClick}>
            <Icon name="trash alternate" />
            削除
          </Button>
        </div>
        <Button
          content={
            <span className={styles.sans_serif}>
              この授業のレビューを見る
              <Icon name="angle right" />
            </span>
          }
          color="green"
          fluid
          style={{ marginTop: 16 }}
          onClick={handleOpenReview}
        />
      </div>
    </Modal>
  );
};
export default CellDetailModal;
