import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { List, ListItem } from "semantic-ui-react";

import RankingListItem from "./RankingListItem";
import RankingListHeader from "./RankingListHeader";

// TODO: 絶対パスに対応
// import * as ROUTES from "@/constants/routes";
// import { useUserRanking } from "@/hooks/user/useUserRanking";
import * as ROUTES from "../../constants/routes";
import { useUserRanking } from "../../hooks/user/useUserRanking";

const ReviewUserRanking = () => {
  const [activeItem, setActiveItem] = useState("month");
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const params = useParams();
  const { univ_id } = params;
  const { ranking } = useUserRanking(univ_id, "review");

  const DEFAULT_DISPLAY_COUNT = 3;
  const DISPLAY_COUNT_LIMIT = 10;
  return (
    <>
      <RankingListHeader
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
      <List selection size="small" style={{ margin: "6px 0 0" }}>
        {ranking[activeItem].length > 0 ? (
          ranking[activeItem]
            .slice(0, DEFAULT_DISPLAY_COUNT)
            .map(({ user, count }, i) => {
              const { userId } = user;
              return (
                <RankingListItem
                  index={i}
                  user={user}
                  count={count}
                  onClick={() =>
                    history.push(
                      ROUTES.APP.USER.HOME.replace(":user_id", userId)
                    )
                  }
                />
              );
            })
        ) : (
          <ListItem>
            <span>ランキングが存在しません</span>
          </ListItem>
        )}
      </List>
      {open && (
        <List selection size="mini" style={{ marginTop: 0 }}>
          {ranking[activeItem]
            .slice(DEFAULT_DISPLAY_COUNT, DISPLAY_COUNT_LIMIT)
            .map(({ user, count }, i) => {
              const { userId } = user;
              return (
                <RankingListItem
                  index={i + DEFAULT_DISPLAY_COUNT}
                  user={user}
                  count={count}
                  onClick={() =>
                    history.push(
                      ROUTES.APP.USER.HOME.replace(":user_id", userId)
                    )
                  }
                />
              );
            })}
        </List>
      )}
      {ranking[activeItem].length > DEFAULT_DISPLAY_COUNT && (
        <List selection size="small" style={{ margin: 0 }}>
          {open ? (
            <ListItem onClick={() => setOpen(false)}>
              <span>閉じる</span>
            </ListItem>
          ) : (
            <ListItem onClick={() => setOpen(true)}>
              <span>もっと見る</span>
            </ListItem>
          )}
        </List>
      )}
    </>
  );
};
export default ReviewUserRanking;
