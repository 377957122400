import React, { useCallback } from "react";
import { Icon, ListItem, ListContent, Header, Button } from "semantic-ui-react";

import styles from "./styles.module.scss";
import {
  CUSTOMIZED_ATTENDANCE_FREQ,
  CUSTOMIZED_SCORING_METHOD,
} from "../../CellSelectContent/constants";
// TODO: 絶対パスに対応
// import { openReviewPageOf } from "@/utils/openReviewPage";
import { openReviewPageOf } from "../../../../../../../../utils/openReviewPage";

const CellListItem = (props) => {
  const { cell, onSelectClick } = props;
  const {
    lecture: {
      course: { name: course_name, course_group, semester },
      teacher_group: { teachers },
    },
    credit,
    student_count,
    credit_easiness,
    content_quality,
    attendance_freq,
    scoring_method,
  } = cell;
  const AFLabel = CUSTOMIZED_ATTENDANCE_FREQ[attendance_freq]
    ? CUSTOMIZED_ATTENDANCE_FREQ[attendance_freq].cons_label
    : null;
  const SMLabel = CUSTOMIZED_SCORING_METHOD[scoring_method]
    ? CUSTOMIZED_SCORING_METHOD[scoring_method].cons_label
    : null;

  const handleOpenReview = useCallback(() => {
    const {
      lecture: {
        course: {
          univ: { univ_id },
          name,
          course_group,
          semester,
        },
        teacher_group: { teachers: teachers_ },
      },
    } = cell;
    openReviewPageOf(
      univ_id,
      name,
      course_group,
      semester,
      teachers_.map((teacher) => teacher.name)
    );
  }, [cell]);

  return (
    <ListItem>
      <ListContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Header as="h4" className={styles.sans_serif} style={{ color: "#333" }}>
          <div style={{ cursor: "pointer" }} onClick={handleOpenReview}>
            {course_name}
          </div>
          <div className={styles.subheader}>
            {course_group}／{semester}
          </div>
          <div className={styles.subheader}>
            <Icon name="graduation" style={{ marginRight: 4 }} />
            {teachers.map((t) => t.name).join("／")}
          </div>
          <div className={styles.subheader}>
            <Icon name="check" style={{ marginRight: 4 }} />
            {credit}単位
          </div>
        </Header>
        <div style={{ display: "flex", justifyItems: "center", flexShrink: 0 }}>
          <div
            className={styles.horizontal_align_center}
            style={{ marginRight: "10px", color: "gray" }}
          >
            <div
              className={styles.vertical_align_center}
              style={{ marginRight: "10px" }}
            >
              {credit_easiness && (
                <span style={{ fontSize: "0.8em" }}>
                  <Icon name="heart" color="yellow" />
                  {(credit_easiness &&
                    (Math.round(credit_easiness * 10) / 10).toFixed(1)) ||
                    "-"}
                </span>
              )}
              {content_quality && (
                <span style={{ fontSize: "0.8em" }}>
                  <Icon name="thumbs up" color="yellow" />
                  {(content_quality &&
                    (Math.round(content_quality * 10) / 10).toFixed(1)) ||
                    "-"}
                </span>
              )}
            </div>
            <div className={styles.vertical_align_center}>
              <span style={{ fontSize: "0.8em" }}>
                <Icon name="users" color="grey" />
                {student_count}人
              </span>
              {AFLabel && (
                <span style={{ fontSize: "0.8em" }}>
                  <Icon name="clipboard check" />
                  {AFLabel || "-"}
                </span>
              )}
              {SMLabel && (
                <span style={{ fontSize: "0.8em" }}>
                  <Icon name="pencil" />
                  {SMLabel || "-"}
                </span>
              )}
            </div>
          </div>
          <div className={styles.vertical_align_center}>
            <Button
              size="mini"
              color="blue"
              content="選択＋"
              className={styles.sans_serif}
              onClick={onSelectClick}
            />
          </div>
        </div>
      </ListContent>
    </ListItem>
  );
};
export default CellListItem;
