// TODO: 絶対パスに対応
// import ReviewApi from "@/api/v2/ReviewApi";
// import { gaEventReview } from "@/api/GA";
import ReviewApi from "../../../api/v2/ReviewApi";
import { gaEventReview } from "../../../api/GA";
import { verifyInput } from "./inputHandlers";

export const postReview = (state, setState, props) => {
    const { univ_id } = props.match.params;
    setState({ is_loading: true }, () => {
      var is_invalid_count = 0;
      var data = {
        timestamp: new Date().getTime() / 1000,
        // user_id: localStorage.getItem("user_session_id"),
        univ_id: univ_id,
        // status: "draft",
      };

      for (const item in state.input_select) {
        data[state.input_select[item].key] = state.input_select[item].value; // ここで投稿データを作成
        if (
          JSON.stringify(state.input_select[item].value) !== JSON.stringify([]) && // teacherは配列が渡ってくるが、空配列の場合は不正な値として扱う
          state.input_select[item].value
        ) {
          verifyInput({ [item]: false });
        } else {
          if (state.input_select[item].required) {
            verifyInput({ [item]: true });
            is_invalid_count += 1;
          }
        }
      }

      if (is_invalid_count > 0) {
        // abort
        alert("未入力の項目があります");
        setState({ is_loading: false });
        return false;
      }

      if (!window.confirm("レビューを投稿します")) {
        // cancel
        setState({ is_loading: false });
        return false;
      } else {
        // post
        const reviewApi = new ReviewApi();
        const postData = {
          univId: data.univ_id,
          courseName: data.course_name,
          courseGroup: data.course_group,
          semester: data.semester,
          teachers: data.teachers,
          year: data.year,
          attendanceFreq: data.attendance_freq,
          scoringMethod: data.scoring_method,
          creditEasiness: data.credit_easiness,
          contentQuality: data.content_quality,
          comment: data.comment,
          anonymous: data.anonymous,
        };

        reviewApi
          .postReview(postData)
          .then((res) => {
            alert("レビューを投稿しました！");
            gaEventReview("post", localStorage.getItem("user_session_id"));
            setState({ is_loading: false }, () => {
              console.log("POST: ", data);
              // Twitter共有
              setState({ is_modal_opened: true });
              // this.pushHistory(ROUTES.APP.HOME.replace(":univ_id", univ_id));
            });
          })
          .catch((err) => {
            alert("投稿に失敗しました…。時間をおいて再度お試しください。");
            console.log(err);
            setState({ is_loading: false });
          });
      }
    });
  };
