// TODO: 絶対パスに対応
// import * as ROUTES from "@/constants/routes";
import * as ROUTES from "../constants/routes";

export const openReviewPageOf = (
  univ_id,
  name,
  course_group,
  semester,
  teachers,
  target = "_blank"
) => {
  const queryParams = new URLSearchParams({
    course: name,
    course_group,
    semester,
    teacher: teachers.join(","),
  });
  const baseUrl = window.location.origin;
  const path = ROUTES.APP.REVIEW.SEARCH.replace(":univ_id", univ_id);
  const query = queryParams.toString() ? `?${queryParams.toString()}` : "";
  const url = baseUrl + path + query;
  window.open(url, target);
};
