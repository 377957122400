import React from "react";
import { Segment, Image } from "semantic-ui-react";

// TODO: 絶対パスに対応
// import RakudaLogo from "@/static/figures/rakuda/rakuda_transparent_theme.png";
import RakudaLogo from "../../../../../../../static/figures/rakuda/rakuda_transparent_theme.png";

export const GeneratingLoader = () => {
  return (
    <Segment
      raised
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "180px",
        height: "180px",
        margin: "0px",
        backgroundColor: "white",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <style>
          {`@keyframes anime {
                0%, 40%, 60%, 80% {
                    transform: rotate(0deg);
                }
                50%, 70% {
                    transform: rotate(5deg);
                }
            }`}
        </style>
        <Image
          src={RakudaLogo}
          alt="loading"
          width={95}
          height={95}
          style={{
            animation: "anime 2.3s ease-out infinite",
          }}
        />
        <p
          style={{
            color: "#206774",
            fontSize: "15px",
            fontWeight: "bold",
            fontFamily: "'M PLUS Rounded 1c'",
          }}
        >
          時間割作成中...
        </p>
      </div>
    </Segment>
  );
};
